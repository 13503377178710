<template>
    <div class="paper-wrapper">
        <div class="paper-wrapper__title">
            <h3>{{ shijuanInfo.particular_year }}</h3>
            <h3>{{ shijuanInfo.title }}</h3>
            <h3>{{ shijuanInfo.deputy_title }}</h3>
            <span>（考试时间 {{ shijuanInfo.exam_time }} 分钟，满分 {{ shijuanInfo.score }} 分）</span>
        </div>
        <template v-for="(item, index) in examList">
            <div class="paper-wrapper__content listen" :key="index">
                <h3 v-if="item.level === 1">
                    {{ item.title }}
                </h3>
                <div class="topic">
                    <div class="topic-title" id="listen-select" v-if="item.level === 2">
                        <div class="topic-title__wrapper">
                            <span class="topic-title__text">
                                {{ item.title }}
                            </span>
                        </div>
                    </div>
                    <div class="topic-content">
                        <span class="topic-content__title" v-if="item.level === 3">{{ item.title }}</span>
                        <span class="topic-content__title" v-if="item.deputy_title">
                            {{ item.deputy_title }}
                        </span>
                        <div class="topic-content__img" v-if="item.materials">
                            <ul class="img-list">
                                <template v-for="(c_item, c_index) in item.materials.images">
                                    <li :key="c_index">
                                        <img :src="c_item.url | img" alt="" />
                                        <span>{{ c_index | getSerial }}</span>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <template v-if="item.timu && item.is_end">
                            <div class="topic-content__body" v-for="(g_item, g_index) in item.timu" :key="g_index">
                                <div class="body-title" v-if="g_item.title !== '' && g_item.title !== null">
                                    <span>{{ g_item.num }}.</span>
                                    <span v-html="g_item.title"></span>
                                </div>
                                <template v-if="g_item.type == 1 || g_item.type == 2">
                                    <span v-if="g_item.title == '' || g_item.title == null">{{ g_item.num }}.</span>
                                    <ul v-for="(h_item, h_index)  in g_item.value" :key="h_index"
                                        :class="[h_item.item_arrange == 1 ? 'body-list1' : 'body-list2']">
                                        <li>
                                            <span class="list-num same-num"
                                                v-if="g_item.type === 1 || g_item.title == null">
                                                {{ h_item.num }}
                                            </span>
                                            <el-radio @change="radioChange(g_item, h_item, i_item.num)"
                                                v-model="h_item.answer" v-for="(i_item, i_index) in h_item.item"
                                                :key="i_index" :label="i_item.num">
                                                <span class="list-title same-title">
                                                    {{ i_index | getSerial }}
                                                </span>
                                                <span class="list-answer same-answer">
                                                    {{ i_item.value }}
                                                </span>
                                            </el-radio>
                                        </li>
                                    </ul>
                                </template>
                                <template v-if="g_item.type == 3">
                                    <el-radio-group v-model="g_item.answer">
                                        <el-radio :label="judge_item.id" @change="radioChangeStage(g_item, judge_index)"
                                            v-for="(judge_item, judge_index) in judge_item" :key="judge_index">
                                            {{ judge_item.name }}</el-radio>
                                    </el-radio-group>
                                </template>
                                <template v-if="g_item.type == 4">
                                    <div class="topic-content__body">
                                        <input type="text" v-model="g_item.answer" :key="index">
                                    </div>
                                </template>
                                <template v-if="g_item.type == 5">
                                    <div class="topic-content__body">
                                        <el-input type="textarea" autosize v-model="g_item.answer"></el-input>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <el-button type="primary" @click="saveData">保存</el-button> {{ examScore }}
    </div>
</template>

<script>
import { mapState } from "vuex";

let that;
let SERIAL_NUM = ["A", "B", "C", "D", "E", "F", "G"]; //选项编号
let JUDGE_ITEM = [{ id: 1, name: '正确' }, { id: 0, name: '错误' }]

export default {
    name: "JuniorSchool",
    data() {
        return {
            kaoshiInfo: {},
            examList: [],
            shijuanInfo: {},
            stageScoreArr: [],//分类分
            judge_item: JUDGE_ITEM,
            djs_obj: '', //倒计时对象
            djs_count: 5, //答错后倒计时5秒
            djs_state: 1, //倒计时状态
            djs_stop: 0,
            audio_obj: [],
            yongshi: "",
            djs_msg: '',
            maxtime: 500,
            kaoshi_status:1,//1考试中，0已结束
            hour:0,
            minutes:0,
            seconds:0
        };
    },
    created() {
        this.getShijuan();
        this.setDjs();
    },
    filters: {
        img: (val) => {
            // msg表示要过滤的数据，a表示传入的参数
            return that.$conf.storage_url + "/" + val;
        },
        getSerial(val) {
            return SERIAL_NUM[val];
        },
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        examScore() {
            let score = 0;
            let stageScoreArr = [];
            let stageItem = { id: '', name: '', score: 0 };
            let stageIndex = 0;
            let stageScore = 0;
            this.examList.forEach((item, index) => {
                if (item.pid == 0 && item.level == 1) {
                    stageItem.id = item.id;
                    stageItem.name = item.title;
                    stageScoreArr.push(Object.assign({}, stageItem));
                    stageIndex++;
                    //console.log('temp_id:'+ temp_id + 'id:' +id);
                }
                //console.log(stageScoreArr);
                //console.log('stageIndex:'+stageIndex);
                item.timu.forEach((timu_item, index_item) => {
                    if (timu_item.is_right == 1) {
                        score = score + timu_item.score;
                        //console.log('stageIndex:'+stageIndex);
                        stageScoreArr[stageIndex - 1].score = stageScoreArr[stageIndex - 1].score + timu_item.score;
                    }
                })
            });
            this.stageScoreArr = stageScoreArr;
            return score;
        }
    },
    beforeCreate: function () {
        that = this;
    },
    methods: {
        getShijuan() {
            this.axios.post("/api/exam/shijuan", { token: this.token, id: this.id }).then((res) => {
                //console.log("paper-item", res.data.data);
                let data = res.data.data;
                this.examList = data.info.shijuan_mold;
                this.kaoshiInfo = data.kaoshiInfo;
                this.shijuanInfo = data.info;
            });
        },
        saveData() {
            console.log('examList', this.examList);
        },
        radioChange(timu_item, timu_value_item, num) {

            if (timu_value_item.right_answer == num) {
                timu_value_item.is_right = 1;
                console.log('right');
            } else {
                timu_value_item.is_right = 0;
                console.log('error');
            }

            let answer = 1;
            for (let i = 0; i < timu_item.value.length; i++) {
                //timu_item.value[i].is_right = 0;
                if (timu_item.value[i].is_right == 0) {
                    answer = 0;
                }
            }
            timu_item.is_right = answer;
            if (timu_item.is_right == 1) {
                timu_item.answer_score = timu_item.score;
            }
            //单选和选择填空
            console.log('timu_item', timu_item);
            console.log('timu_value_item', timu_value_item);
            console.log('num', num);
        },
        radioChangeStage(timu_item, index) {
            //判断            
            if (timu_item.right_answer == index) {
                timu_item.is_right = 1;
                console.log('is_right', index);
            } else {
                timu_item.is_right = 0;
                console.log('error', index);
            }
            console.log('timu_item', timu_item);
        },

        /**
         * [study_djs 倒计时]
         * @param  {Number} value [description]
         * @return {[type]}       [description]
         */
        setDjs(value = 0) {
            var that = this;
            that.djs_count = that.maxtime;
            if (this.djs_obj) {
                clearInterval(this.djs_obj);
            }
            if (value > 0) {
                that.djs_count = value;
            }

            //console.log("倒计时" + that.djs_count);
            that.djs_state = 1;
            let maxtime = that.djs_count;
            this.djs_obj = setInterval(function () {
                if (that.djs_state != 1) {
                    clearInterval(that.djs_obj);
                    that.djs_msg = "";
                    return false;
                }
                if (that.djs_stop == 1) {
                    return;
                }
                //djs_count,djs_obj,djs_state,djs_msg,djs_stop,yongshi
                

                if (maxtime >= 0) {
                    let hour = 0;
                    let minutes = Math.floor(maxtime / 60);
                    if (minutes > 60) {
                        hour = Math.floor(minutes / 60);
                        minutes = Math.floor(minutes % 60);
                    }
                    let seconds = Math.floor(maxtime % 60);
                    if (hour < 10) {
                        hour = "0" + hour;
                    }
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }
                    if (seconds < 10) {
                        seconds = "0" + seconds;
                    }
                    ///that.djs_msg = hour + ":" + minutes + ":" + seconds;
                    console.log(that.djs_msg);
                    if (that.kaoshi_status == 1) {
                        --maxtime;
                        that.zj_yongshi();
                    }
                    that.hour = hour;
                    that.minutes = minutes;
                    that.seconds = seconds;
                } else {
                    clearInterval(that.djs_obj);
                    that.djs_msg = "";
                    that.kaoshi_status = 0;
                    that.saveData();
                }
            }, 1000);
        },
        zj_yongshi() {
            var that = this;
            that.yongshi++;
            var hour = Math.floor(that.yongshi / 3600);
            var minute = Math.floor((that.yongshi - hour * 3600) / 60);
            var second = that.yongshi - hour * 3600 - minute * 60;
            if (hour > 0) {
                that.yongshi_time = hour + "时" + minute + "分" + second + "秒";
            } else {
                that.yongshi_time = minute + "分" + second + "秒";
            }
            console.log(that.yongshi_time);
        },
        clearDjs() {
            if (this.djs_obj) {
                clearInterval(this.djs_obj);
            }
        },
    },
    destroyed() {
        //window.removeEventListener("keyup", this.key_up);
        //window.removeEventListener("keydown", this.key_down);
        this.clearDjs();
    }
};
</script>

<style lang="scss" scoped>
</style>
